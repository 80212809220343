<template>
  <div class="container about-page">
    <div class="meta-header centered center-align">
      <h1>Contact us</h1>
      <hr>
    </div>
    <div class="page-content centered">
      <p>
        Our team is here to help you with anything. Please feel free to contact us by email at <a href="mailto:careytsim@ststradeco.com" style="text-decoration:underline">careytsim@ststradeco.com</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
name: "support"
}
</script>

<style scoped>

</style>
