<template>
  <div id="app">
    <top-link></top-link>
    <router-view />
    <footer-link :bottomFixed="bottomFixed" :fixedDefault="fixedDefault"></footer-link>
  </div>
</template>

<script>
import FooterLink from "@/components/footer/FooterLink";
import TopLink from "@/components/topLink/TopLink";
export default {
  components: {
    FooterLink,
    TopLink
  },
  data() {
    return {
      bottomFixed: false,
      fixedDefault: false
    }
  },
  watch:{
    $route(){
      window.scrollTo(0,0);
      this.judgeBottomFixed();
    },
  },
  created() {
    console.log(1);
    this.judgeBottomFixed();
  },
  methods: {
    judgeBottomFixed() {
      const isDailyPage = this.$route.path.includes("daily");
      const isContactPage = this.$route.path.includes("contact");
      this.bottomFixed = isDailyPage;
      this.fixedDefault = isContactPage;
    }
  }
};
</script>
