<template>
  <nav class="navbar navbar-white navbar-fixed-top" role="navigation">
    <div class="container">
      <div class="icon-logo"></div>
      <div class="pull-left navbar-wrap navbar-left-menu">
        <a href="/daily-quotes" class="navbar-link hidden-xs ">Daily Quotes</a>
        <a href="/life-quotes" class="navbar-link hidden-xs ">Life Quotes</a>
      </div>
      <div class="pull-right navbar-wrap navbar-buttons-wrap">
        <button type="button" @click="showMenu=!showMenu"
                class="btn btn-quotlr-semi-orange navbar-btn visible-xs navbar-sandwitch visible-xs hidden-sm pull-right">
          Menu
        </button>
      </div>
      <div class="clear-fix align-right" v-if="showMenu">
        <ul class="navbar-user-menu animated bounceInRight duration-0-5s">
          <li><a href="/daily-quotes" class="visible-xs visible-sm">Daily Quotes</a></li>
          <li><a href="/life-quotes" class="visible-xs visible-sm">Life Quotes</a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
name: "TopLink",
  data() {
    return {
      showMenu: false
    }
  }
}
</script>

<style scoped>

</style>
