<template>
  <div>
    <section class="herosection">
      <div class="container indexlong">
        <div class="row">
          <div class="col-sm-12 quotlrtopic">
            <h1>Popular Quotations on Life, Love and Success</h1>
          </div>
        </div>
        <div class="row">

          <div class="col-sm-12"><br />
            What are the best quotes? Read quality quotes from most famous people. Daily Quotes lets you search and explore most <strong>inspiring quotes</strong>. It’s a place to find <em>inspirational and motivational sayings</em>. Improve your life by how you think. <b>Quotes to change yourself</b>, learn and grow.
            <br />
          </div>
        </div>
        <hr class="main-hr" />
      </div>
    </section>
    <section>
      <div class="container indexlong">
        <div class="row">
          <div class="col-sm-8 col-xs-12 indexpopquotes">
            <h2>Popular quotes</h2>
            <hr />
            <div class="quote-list">
              <div class="quote dbquote quote-db-902141" quote_id="902141">
                <blockquote><p><span class="quote-text">Love your enemies because they bring out the best in you.</span></p>
                  <small class="quote-author">
                    <cite title=" Philosopher Friedrich Nietzsche Quotes"><span class="author-name">Friedrich Nietzsche</span></cite>
                  </small>
                </blockquote>
              </div>
              <hr /> <div class="quote dbquote quote-db-1080000" quote_id="1080000">
              <blockquote><p><span class="quote-text">Don't wait for miracles, your whole life is a miracle.</span></p>
                <small class="quote-author">
                  <cite title=" Physicist Albert Einstein Quotes"><span class="author-name">Albert Einstein</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-942686" quote_id="942686">
              <blockquote><p><span class="quote-text">If you want to destroy any nation without war, make adultery or nudity common in the young generation</span></p>
                <small class="quote-author">
                  <cite title=" Sultan of Egypt Saladin Quotes"><span class="author-name">Saladin</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-645874" quote_id="645874">
              <blockquote><p><span class="quote-text">There is a longing among all people and creatures to have a sense of purpose and worth. To satisfy that common longing in all of us we must respect each other.</span></p>
                <small class="quote-author">
                  <cite title=" Author Chief Dan George Quotes"><span class="author-name">Chief Dan George</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-854424" quote_id="854424">
              <blockquote><p><span class="quote-text">All my weak days have a common cause - I have neglected communion with God through my neglect of the Scriptures & prayer. When will I learn?</span></p>
                <small class="quote-author">
                  <cite title=" Sermonizer Paul Washer Quotes"><span class="author-name">Paul Washer</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1241766" quote_id="1241766">
              <blockquote><p><span class="quote-text">The most common form of despair is not being who you are.</span></p>
                <small class="quote-author">
                  <cite title=" Philosopher Soren Kierkegaard Quotes"><span class="author-name">Soren Kierkegaard</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1025259" quote_id="1025259">
              <blockquote><p><span class="quote-text">Woman is born free and lives equal to man in her rights. Social distinctions can be based only on the common utility.</span></p>
                <small class="quote-author">
                  <cite title=" Playwright Olympe de Gouges Quotes"><span class="author-name">Olympe de Gouges</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1065990" quote_id="1065990">
              <blockquote><p><span class="quote-text">The creator of the heavens obeys a carpenter; the God of eternal glory listens to a poor virgin. Has anyone ever witnessed anything comparable to this? Let the philosopher no longer disdain from listening to the common laborer; the wise, to the simple; the educated, to the illiterate; a child of a prince, to a peasant.</span></p>
                <small class="quote-author">
                  <cite title=" Priest Anthony of Padua Quotes"><span class="author-name">Anthony of Padua</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-670898" quote_id="670898">
              <blockquote><p><span class="quote-text">It became easy for me to detach myself from the course of life, so that while my hands and mind were engaged in the common affairs of every day, my spirit maintained its attitude of communion with God.</span></p>
                <small class="quote-author">
                  <cite title="  John G. Lake Quotes"><span class="author-name">John G. Lake</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-471112" quote_id="471112">
              <blockquote><p><span class="quote-text">God is not looking for men of great faith, He is looking for common men to trust His great faithfulness.</span></p>
                <small class="quote-author">
                  <cite title=" Missionary Hudson Taylor Quotes"><span class="author-name">Hudson Taylor</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-946475" quote_id="946475">
              <blockquote><p><span class="quote-text">Life is pitiful, death so familiar, suffering and pain so common, yet I would not be anywhere else. Do not wish me out of this or in any way seek to get me out, for I will not be got out while this trial is on. These are my people, God has given them to me, and I will live or die with for Him and His glory.</span></p>
                <small class="quote-author">
                  <cite title=" Missionary Gladys Aylward Quotes"><span class="author-name">Gladys Aylward</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1261737" quote_id="1261737">
              <blockquote><p><span class="quote-text">We try to abolish intervals by our manic insistence on keeping busy, on doing something. And as a result, all we succeed in doing is destroying all hope of tranquility. ... . You have to learn to immerse yourself in the silences between.</span></p>
                <small class="quote-author">
                  <cite title=" Author Lyall Watson Quotes"><span class="author-name">Lyall Watson</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-537018" quote_id="537018">
              <blockquote><p><span class="quote-text">No Fear, No Hesitation, No Surprise, No Doubt</span></p>
                <small class="quote-author">
                  <cite title=" Soldier Miyamoto Musashi Quotes"><span class="author-name">Miyamoto Musashi</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-58103" quote_id="58103">
              <blockquote><p><span class="quote-text">Men don't care what's on TV. They only care what else is on TV.</span></p>
                <small class="quote-author">
                  <cite title=" Comedian Jerry Seinfeld Quotes"><span class="author-name">Jerry Seinfeld</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1192533" quote_id="1192533">
              <blockquote><p><span class="quote-text">Looking at cleavage is like looking at the sun. You don't stare at it, it's too risky. You get a sense of it and then you look away.</span></p>
                <small class="quote-author">
                  <cite title=" Comedian Jerry Seinfeld Quotes"><span class="author-name">Jerry Seinfeld</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-712650" quote_id="712650">
              <blockquote><p><span class="quote-text">Only a burning patience will lead to the attainment of a splendid happiness.</span></p>
                <small class="quote-author">
                  <cite title=" Writer Pablo Neruda Quotes"><span class="author-name">Pablo Neruda</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-897814" quote_id="897814">
              <blockquote><p><span class="quote-text">The people who test your patience are a blessing.. Without them, you can't practice patience.</span></p>
                <small class="quote-author">
                  <cite title=" Instructor Nouman Ali Khan Quotes"><span class="author-name">Nouman Ali Khan</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-82474" quote_id="82474">
              <blockquote><p><span class="quote-text">Patience is the companion of wisdom.</span></p>
                <small class="quote-author">
                  <cite title=" Philosopher Saint Augustine Quotes"><span class="author-name">Saint Augustine</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1272101" quote_id="1272101">
              <blockquote><p><span class="quote-text">Patience is power. Patience is not an absence of action; rather it is "timing" it waits on the right time to act, for the right principles and in the right way.</span></p>
                <small class="quote-author">
                  <cite title=" Clergyman Fulton J. Sheen Quotes"><span class="author-name">Fulton J. Sheen</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-45118" quote_id="45118">
              <blockquote><p><span class="quote-text"><a href="/quotes-about-persistence">Patience, persistence and perspiration make</a> an unbeatable combination for success.</span></p>
                <small class="quote-author">
                  <cite title=" Writer Napoleon Hill Quotes"><span class="author-name">Napoleon Hill</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1102570" quote_id="1102570">
              <blockquote><p><span class="quote-text">Purity, patience and perseverance are the three essentials to success and above all love.</span></p>
                <small class="quote-author">
                  <cite title=" Clergyman Swami Vivekananda Quotes"><span class="author-name">Swami Vivekananda</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1131893" quote_id="1131893">
              <blockquote><p><span class="quote-text">Have patience. All things are difficult before they become easy.</span></p>
                <small class="quote-author">
                  <cite title=" Poet Saadi Quotes"><span class="author-name">Saadi</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-117753" quote_id="117753">
              <blockquote><p><span class="quote-text">Adopt the pace of nature, her secret is patience.</span></p>
                <small class="quote-author">
                  <cite title=" Poet Ralph Waldo Emerson Quotes"><span class="author-name">Ralph Waldo Emerson</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-391458" quote_id="391458">
              <blockquote><p><span class="quote-text">If you do not find peace within, you will not find it anywhere else. The Goal of Life is the attainment of Peace and not the achievement of power, name, fame and wealth.</span></p>
                <small class="quote-author">
                  <cite title=" Author Sivananda Quotes"><span class="author-name">Sivananda</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1035189" quote_id="1035189">
              <blockquote><p><span class="quote-text">Boys, be ambitious. Be ambitious not for money, not for selfish aggrandizement, not for the evanescent thing which men call fame. Be ambitious for the attainment of all that a man can be.</span></p>
                <small class="quote-author">
                  <cite title=" Explorer William Clark Quotes"><span class="author-name">William Clark</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <div class="quote dbquote quote-db-1129829" quote_id="1129829">
              <blockquote><p><span class="quote-text">In the Universal Declaration of Human Rights (December 1948) in most solemn form, the dignity of a person is acknowledged to all human beings; and as a consequence there is proclaimed, as a fundamental right, the right of free movement in search for truth and in the attainment of moral good and of justice, and also the right to a dignified life.</span></p>
                <small class="quote-author">
                  <cite title=" Clergyman Pope John XXIII Quotes"><span class="author-name">Pope John XXIII</span></cite>
                </small>
              </blockquote>
            </div>
              <hr /> <br /><br /><br />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
name: "LifeQuote"
}
</script>

<style scoped>

</style>
