<template>
  <div class="footer centered center-align" :class="{'footer-fixed': bottomFixed, 'footer-fixed-default': fixedDefault}">
    <hr>
    <router-link to="/">Daily Quotes</router-link>
    <router-link to="/privacy-terms">Privacy & Terms</router-link>
    <router-link to="/contact-us">contact</router-link>
    <p class="morequotes">Daily Quotes lets you read quality quotes to improve your life. You'll find how to achieve inner peace and happiness with our quotes. No matter if you're working hard to achieve goals like love and change, here are collections with quotes to help you. </p>
    <p>2021 &copy; Daily Quotes Apps</p>
  </div>
</template>

<script>
export default {
  props: {
    bottomFixed: {
      type: Boolean,
    },
    fixedDefault: {
      type: Boolean,
    },
  },
  name: "FooterLink"
}
</script>

<style scoped>
@media (min-width: 768px) {
  .footer-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
.footer-fixed-default {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
