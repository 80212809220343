import Vue from "vue";
import VueRouter from "vue-router";
import DailyQuote from "../views/DailyQuote.vue";
import LifeQuote from "../views/LifeQuote.vue";
import terms from "../views/terms.vue";
import support from "../views/support.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/daily-quotes"
  },
  {
    path: "/daily-quotes",
    name: "daily",
    component: DailyQuote
  },{
    path: "/life-quotes",
    name: "life",
    component: LifeQuote
  },{
    path: "/privacy-terms",
    name: "privacy",
    component: terms
  },{
    path: "/contact-us",
    name: "contact",
    component: support
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
