<template>
  <div class="container list-entry single-db-quote-page" id="explore-quote">
    <div class="center-align">
      <div class="user-avatar"><img :src="getPic(item.icon)" alt="Ren"/></div>
      <div class="quote dbquote quote-db-27948 quote-overlayed" quote_id="27948">
        <div itemscope itemtype="http://schema.org/CreativeWork">
          <span itemprop="citation"><span itemprop="name" class="quote-text big-text"><h1>{{
              item.title
            }}</h1></span></span>
          <small class="quote-author">
            <cite title="Ren"><span class="author-name">
                <span itemprop="author">
                <span>{{ item.name }}</span>
              </span> in
                <span itemprop="genre">
                  <span class="mini-author-tag">{{ item.area }}</span>
                </span>
              </span>
            </cite>
            <li class="quote-meta-item quote-like" data-quoteid="27948" data-db="dbquote">
              <i class="fa fa-bookmark like-icon"></i>
              <span class="likes-count">{{ item.num }}</span>
              <span class="like-text">Bookmark</span>
            </li>
          </small></div>
      </div>
      <br><br>
      <a @click="getItem" class="btn btn-quotlr-orange btn-spaced-letters centered btn-lg">NEXT QUOTE &rarr;</a>
    </div>
  </div>
</template>

//
<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      item: {},
      currentIndex: -1,
      list: [{
        icon: "112-benjamin_franklin_quotes.jpeg",
        num: 8,
        title: "There is much difference between imitating a man and counterfeiting him.",
        name: "BENJAMIN FRANKLIN",
        area: "Plagiarism"
      }, {
        icon: "85-charles_dickens_quotes.jpeg",
        num: 22,
        title: "A boy's story is the best that is ever told.",
        name: "CHARLES DICKENS",
        area: "Youth"
      }, {
        icon: "1285-william-arthur-ward-quotes.jpeg",
        num: 15,
        title: "If you can imagine it,You can achieve it.If you can dream it,You can become it.",
        name: "WILLIAM ARTHUR WARD",
        area: "Ambition"
      }, {
        icon: "265-william_shakespeare_quotes.jpeg",
        num: 8,
        title: "I have bought golden opinions from all sorts of people.",
        name: "WILLIAM SHAKESPEARE",
        area: "Publicity"
      }, {
        icon: "24-henry-ward-beecher-quotes.jpeg",
        num: 9,
        title: "Private opinion is weak, but public opinion is almost omnipotent.",
        name: "HENRY WARD BEECHER",
        area: "Opinion"
      }, {
        icon: "440-african_proverbs_quotes.jpeg",
        num: 305,
        title: "Do not look where you fell, but where you slipped.",
        name: "AFRICAN PROVERBS",
        area: "Failure"
      }, {
        icon: "218-nietzsche_friedrich_quotes.jpeg",
        num: 9,
        title: "If you believed more in life you would fling yourself less to the moment.",
        name: "FRIEDRICH NIETZSCHE",
        area: "Life"
      }, {
        icon: "1373-gore-vidal-quotes.jpeg",
        num: 5,
        title: "I'm all for bringing back the birch, but only between consenting adults.",
        name: "GORE VIDAL",
        area: "Punishment"
      }, {
        icon: "4674-gelett-burgess-quotes.jpeg",
        num: 5,
        title: "Without bigots, eccentrics, cranks and heretics the world would not progress.",
        name: "GELETT BURGESS",
        area: "Progress"
      }, {
        icon: "340-goethe_johann_wolfgang_von_quotes.jpeg",
        num: 4,
        title: "Beauty is everywhere a welcome guest.",
        name: "JOHANN WOLFGANG VON GOETHE",
        area: "Beauty"
      }]
    }
  },
  created() {
    this.getItem();
  },
  methods: {
    getPic(name) {
      return require("@/assets/img/icon/" + name);
    },
    getItem() {
      const index = Math.floor(Math.random() * 10);
      if (index === this.currentIndex) {
        this.getItem();
      } else {
        this.currentIndex = index;
        this.item = this.list[index];
      }
    }
  }
};
</script>

<style scoped>

</style>
